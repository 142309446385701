var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('loading',{attrs:{"show":_vm.is_loading}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"mt-1 p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Role Name'),"label-for":"role"}},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"autofocus":"","placeholder":"Name","trim":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"sales_permission"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$i18n.t('sales_permission'),"label-for":"role"}},[_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.sales_permission_options,"reduce":function (val) { return val.value; },"label":"title"},model:{value:(_vm.sales_permission),callback:function ($$v) {_vm.sales_permission=$$v},expression:"sales_permission"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"brand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$i18n.t('brand'),"label-for":"brand"}},[_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.brands},model:{value:(_vm.brand),callback:function ($$v) {_vm.brand=$$v},expression:"brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mt-1"},[_c('b-form-checkbox',{attrs:{"name":"checkbox-1"},model:{value:(_vm.s4s_staff),callback:function ($$v) {_vm.s4s_staff=$$v},expression:"s4s_staff"}},[_vm._v(" "+_vm._s(_vm.$i18n.t('s4s_staff'))+" ")])],1)],1)],1),_c('validation-provider',{attrs:{"name":"permissions","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-card',{staticClass:"border mt-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"font-medium-2"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v(" "+_vm._s(_vm.$i18n.t('Permission')))])],1)],1),_c('b-table',{staticClass:"mb-0",attrs:{"items":_vm.permissions,"responsive":"","striped":""},scopedSlots:_vm._u([{key:"cell(module)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell()",fn:function(data){return [_c('b-form-checkbox',{attrs:{"checked":data.value},on:{"change":function($event){return _vm.changeCheckboxPermission($event, data)}},model:{value:(_vm.permissions[data.index][data.field.key]),callback:function ($$v) {_vm.$set(_vm.permissions[data.index], data.field.key, $$v)},expression:"permissions[data.index][data.field.key]"}})]}}],null,true)})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$i18n.t('Update'))+" ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }